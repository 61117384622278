import { DeepMap, FieldValues } from 'react-hook-form'

import { PacsSourceSecretsSettingsQuery } from '@/graphql/generated/operations'
import {
  PacsSourceFieldInput,
  PacsSourceFieldInstance,
  PacsSourceFieldInstanceInput,
} from '@/graphql/generated/schemas'

import {
  FormInputsT,
  PacsSourceInstanceI,
  PacsSourceSecretsI,
} from '../types/types'

export const ACS_KEY = 'acs'
export const INSTANCE_NAME_KEY = 'instanceName'
export const SECURE_FIELD_MASK = '********'

export const sortByName = (
  f1: PacsSourceFieldInstance,
  f2: PacsSourceFieldInstance
) => f1?.pacsSourceField?.name?.localeCompare(f2?.pacsSourceField?.name)

export const getPacsSourceSecureFieldIds = (
  pacsSourceInstance: PacsSourceInstanceI
) =>
  pacsSourceInstance.fields?.flatMap((f) =>
    f.pacsSourceField.secure ? f.id : []
  )

export const getPacsSourceFields = (
  obj: FormInputsT
): PacsSourceFieldInput[] => {
  return Object.entries(obj).flatMap(
    ([pacsSourceFieldId, value]: [string, string]) =>
      pacsSourceFieldId !== ACS_KEY && pacsSourceFieldId !== INSTANCE_NAME_KEY
        ? { pacsSourceFieldId, value }
        : []
  )
}

// Returns a PacsSourceFieldInstanceInput[] from the values from each 'dirty' input
// We dont want to include 'instanceName' as a field
export const getPacsSourceInstanceFields = (
  dirtyFields: DeepMap<FieldValues, true>,
  getValues: (payload?: string | string[]) => Record<string, string>
): PacsSourceFieldInstanceInput[] => {
  return Object.entries(dirtyFields).flatMap(
    ([pacsSourceFieldInstanceId]: [string, string]) =>
      pacsSourceFieldInstanceId !== INSTANCE_NAME_KEY
        ? {
            pacsSourceFieldInstanceId,
            value: getValues(pacsSourceFieldInstanceId),
          }
        : []
  )
}

export const getPacsSourceInstances = (
  data: PacsSourceSecretsSettingsQuery
): PacsSourceSecretsI[] => {
  const sources = data?.sources?.edges.map((e) => e?.node) || []
  const instances = data?.instances?.edges.map((e) => e?.node) || []
  return sources.map((source) => ({
    id: source.id,
    name: source.name,
    instances: instances
      .filter((i) => i.pacsSource.id === source.id)
      .map((instance) => ({
        ...instance,
        sourceId: source.id,
        sourceName: source.name,
        fields: [...instance.fields].sort(sortByName),
      })),
  }))
}
